import React from "react";
import CodeWeekPaket from "./CodeWeekPaket.jsx";
import { Link } from "react-router-dom";

function CodeWeekPaketTwo(props) {
  const paketLogo = "/images/code-week/tiles/B3.svg";
  const text =
    "Diskutiert die ethischen Aspekte von zukünftigen KI-Technologien, oder erfahrt mehr über die Geschichte von IT-Pionierfrauen und ihre Errungenschaften.";

  const pioneerWomenTileImg = "/images/code-week/K1.svg";
  const pioneerWomenLink = "/milestones/pioneer-women";
  const pioneerWomenDescription =
    "Besucht unsere Ausstellung einiger bekannter Pionierinnen aus dem MINT-Bereich, die mit ihren Errungenschaften noch heute unsere Gesellschaft prägen.";
  const pioneerWomenTitle = "IT-Pionierfrauen";
  const pioneerWomenSubtitle = "Frauenpower!";

  const ethicsTileImg = "/images/code-week/K2.svg";
  const ethicsLink = "/ethics/ethically-questionable/intro-video";
  const ethicsDescription =
    "Hier lernst du KI-Anwendungen zu hinterfragen und diskutierst mit deiner eigenen Arbeitsgruppe Vorteile und Nachteile.";
  const ethicsTitle = "Ethisch fragwürdig";
  const ethicsSubtitle = "Diskutiere KI-Anwendungen";

  return (
    <>
      <CodeWeekPaket text={text} paketLogo={paketLogo}>
        <section className="content">
          <div className="container-fluid home-wrapper">
            <div className="subpath-tiles">
              <Link
                to={pioneerWomenLink}
                target="_blank"
                className="subpath-tile"
              >
                <div className="tile-wrapper">
                  <img className="image" src={pioneerWomenTileImg} />
                  <div className="row2 primary-color-lightblue-background">
                    <div className="tile-sub-header">
                      {pioneerWomenSubtitle}
                    </div>
                    <div className="tile-header">{pioneerWomenTitle}</div>
                    <div className="tile-description">
                      {pioneerWomenDescription}
                    </div>
                  </div>
                </div>
              </Link>
              <Link to={ethicsLink} target="_blank" className="subpath-tile">
                <div className="tile-wrapper">
                  <img className="image" src={ethicsTileImg} />
                  <div className="row2 primary-color-lightblue-background">
                    <div className="tile-sub-header">{ethicsSubtitle}</div>
                    <div className="tile-header">{ethicsTitle}</div>
                    <div className="tile-description">{ethicsDescription}</div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </section>
      </CodeWeekPaket>
    </>
  );
}

export default CodeWeekPaketTwo;
