import React, { useState, useRef } from "react";
import { UnControlled as CodeMirror } from "react-codemirror2";
import CodeSolution from "../course/CodeSolution";
import Loader from "../common/loader/loader";
import "codemirror/lib/codemirror.css";
import "codemirror/keymap/sublime";
import "codemirror/theme/elegant.css";
import "codemirror/mode/python/python";
import "codemirror/mode/shell/shell";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCode,
  faUndoAlt,
  faSave,
  faPlay,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

const CodeEditor = (props) => {
  const {
    courseItem,
    loadSavedCode,
    runCode,
    saveCode,
    isPyodideLoaded = true,
  } = props;

  const defaultCode = courseItem.content;
  const [showSolution, setShowSolution] = useState(false);
  const [output, setOutput] = useState(">>>");
  const [matplotlibOutput, setMatplotlibOutput] = useState(null);
  const [code, setCode] = useState(courseItem.content);
  const [codeToRun, setCodeToRun] = useState(courseItem.content);
  const [keyVal, setkeyVal] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const plotOutputImageRef = useRef(null);

  const toggleSolutionPanel = (event) => {
    setShowSolution(!showSolution);
  };

  const reset = () => {
    setCode(defaultCode);
    setCodeToRun(defaultCode);
    setkeyVal(keyVal + 1);

    /* The above line - updating the key - is to force the react codemirror component to update.
     * On user input to the coding box, the code doesnt get update (only codeToRun gets updated)
     * in this case, on reset -> when code gets set to defultCode there is no change in code (since
     * the existing value of code is default code) and hence wont trigger rerender of the codemirror component */
  };

  // load with an async loadSavedCode helper - loadSavedCode in courseConent will have to be made async and must return a response
  // Currently not used, - using sync load with callback to update states
  const _loadSavedCode = () => {
    loadSavedCode(courseItem.file_id).then(({ data: response }) => {
      if (response.output && response.output.length > 0) {
        setCode(response.output[0].content);
        setCodeToRun(response.output[0].content);
        setShowLoader(false);
      } else {
      }
    });
  };

  const showMessage = (msg, type) => {
    switch (type) {
      case "message":
        setMessage(msg);
        break;
      case "warning":
        setWarningMessage(msg);
        break;
      case "error":
        setErrorMessage(msg);
        break;
      default:
        return;
    }
    setTimeout(() => {
      clearMessages();
    }, 3000);
  };

  const clearMessages = () => {
    setMessage("");
    setErrorMessage("");
    setWarningMessage("");
  };

  const updateStateForLoad = (value) => {
    setCode(value);
    setCodeToRun(value);
    setkeyVal(keyVal + 1);
    setShowLoader(false);
  };

  const updateStateForRun = (outputText, matplotlibOutput) => {
    setOutput(outputText);
    if (matplotlibOutput != null) {
      setMatplotlibOutput(matplotlibOutput);
    }
    setShowLoader(false);
  };

  const handleError = (error) => {
    console.log(error);
    setShowLoader(false);
  };

  const load = () => {
    setShowLoader(true);
    loadSavedCode(courseItem.file_id, updateStateForLoad, (e) => {
      showMessage(e, "warning");
      setShowLoader(false);
    });
  };

  const save = () => {
    setShowLoader(true);
    saveCode(courseItem.file_id, codeToRun)
      .then(({ data: response }) => {
        //on success response = {success:true}
        showMessage("Saved data successfully.", "message");
        setShowLoader(false);
      })
      .catch((e) => {
        setShowLoader(false);
        showMessage("Something went wrong! Could not save your code.", "error");
      });
  };

  // Run code, with callbacks to update states
  const run = () => {
    setShowLoader(true);
    let code = codeToRun ? codeToRun : "";
    runCode(code, updateStateForRun, (e) => {
      setOutput(e.message);
      setShowLoader(false);
    });
  };

  return (
    <div className="code-panel-wrapper">
      <div className="code-panel">
        <CodeMirror
          id="codeblock"
          key={keyVal}
          options={{
            theme: "elegant",
            keyMap: "sublime",
            mode: "python",
            lineNumbers: true,
            lineWrapping: true,
          }}
          value={code}
          onChange={(editor, data, value) => {
            setCodeToRun(value);
          }}
        />
        {message && (
          <div className="alert alert-success" role="alert">
            {message}
          </div>
        )}
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
        {warningMessage && (
          <div className="alert alert-warning" role="alert">
            {warningMessage}
          </div>
        )}
        {courseItem && courseItem.solution && (
          <button data-tip data-for="load_soln" onClick={toggleSolutionPanel}>
            <FontAwesomeIcon className="icon" icon={faFileCode} />
            {/* {showSolution? "Lösung verstecken":"Lösung anzeigen"} */}

            <ReactTooltip id="load_soln">
              <span>Lösung verstecken/anzeigen</span>
            </ReactTooltip>
          </button>
        )}
        <button data-tip data-for="reset" onClick={reset} label="Reset Code">
          <FontAwesomeIcon className="icon" icon={faUndoAlt} />
          <ReactTooltip id="reset">
            <span>Reset Code</span>
          </ReactTooltip>
        </button>
        {loadSavedCode && (
          <button
            data-tip
            data-for="load"
            onClick={load}
            label="load saved Code"
          >
            <FontAwesomeIcon className="icon" icon={faDownload} />
            <ReactTooltip id="load">
              <span>Load your code</span>
            </ReactTooltip>
          </button>
        )}
        {saveCode && (
          <button data-tip data-for="save" onClick={save} label="save Code">
            <FontAwesomeIcon className="icon" icon={faSave} />
            <ReactTooltip id="save">
              <span>Save your code</span>
            </ReactTooltip>
          </button>
        )}
        {props.isPyodideLoaded && (
          <button data-tip data-for="run" onClick={run} label="Run Code">
            <FontAwesomeIcon className="icon" icon={faPlay} />
            <ReactTooltip id="run">
              <span>Run code</span>
            </ReactTooltip>
          </button>
        )}
      </div>

      {showSolution && (
        <CodeSolution
          className="code-solution-panel"
          solution={courseItem.solution}
        />
      )}

      {matplotlibOutput != null && (
        <img src={matplotlibOutput} className="pyplotfigure" />
      )}

      <div className="terminal-panel">
        <CodeMirror
          id="codeblock"
          options={{
            theme: "idea",
            mode: "",
            lineNumbers: false,
            readOnly: true,
          }}
          className="terminal-block"
          value={output}
          disabled
        />
      </div>
      {showLoader && (
        <div className="loader-panel">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default CodeEditor;
