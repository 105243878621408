import React, { Component } from "react";
import authService from "../services/authService";
import { Link } from "react-router-dom";
import Button from "../components/common/button";
import material from "../pdfs/Unterrichtsmaterialien_KI_ChatGPT_X76_16_4.pdf";
import chatGPTPDF from "../pdfs/Artikel_ChatGPT.pdf";
import aiCreativityPDF from "../pdfs/Artikel_Kreativität_der_KI.pdf";
class Home extends Component {
  state = {
    paths: [],
    showAlert: false,
    showCountdown: true,
    countdown: "",
    showSchoolCountdown: true,
    schoolCountdown: "",
    showPanel: false,
    panel: 1,
    isStudent: false,
  };

  componentDidMount() {
    let paths = [
      {
        title: "Woher Kommt KI?",
        description:
          "Lerne über die Hintergründe der KI und ihre Entwicklungen.",
        info: "In der Kategorie Meilensteine der KI werden die wichtigsten Schritte in der Entwicklung von KI behandelt. Personen, Leistungen und Events werden in den historischen Kontext eingebettet. Die Aufgaben aus dieser Kategorie eignen sich bestens für den Informatik-, NWT- Technik-, Ethik- und Geschichtsunterricht, ebenso wie für AGs. Der Inhalt der Aufgaben fördert das Allgemeinwissen rund um das Thema Künstliche Intelligenz.",
        link: "/milestones",
        image: "milestones.svg",
        styleClass: "primary-color-blue-background",
      },
      {
        title: "Wie baut man eine KI?",
        description: "Programmiere deine erste KI.",
        info: "In der Kategorie Programmieren werden die Grundlagen für die Programmierung einer KI vermittelt. Ein Grundlagenkurs in der Programmiersprache Python, KI-Programmiertutorials und Übungsaufgaben aus einem Aufgabenpool lassen sich hervorragend in den Informatikunterricht oder in Programmier-AGs einbinden.",
        link: "/programming",
        image: "programming.svg",
        styleClass: "primary-color-lightblue-background",
      },
      {
        title: "Wie ght man mit KI um?",
        description: "Diskutiere, wie sich KI auf die Gesellschaft auswirkt.",
        info: "In der Kategorie Programmieren werden die Grundlagen für die Programmierung einer KI vermittelt. Ein Grundlagenkurs in der Programmiersprache Python, KI-Programmiertutorials und Übungsaufgaben aus einem Aufgabenpool lassen sich hervorragend in den Informatikunterricht oder in Programmier-AGs einbinden.",
        link: "/ethics",
        image: "ethics.svg",
        styleClass: "primary-color-lightgreen-background",
      },
    ];
    if (authService.getCurrentUser()) {
      const isStudent = authService.isUserStudent();
      this.setState({
        paths: paths,
        isStudent: isStudent,
      });
    } else {
      this.setState({
        paths: paths,
        isStudent: true,
      });
    }
    // console.log(user)
  }

  removeAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  toggleModal = (item) => {
    this.setState({
      showPanel: !this.state.showPanel,
      panel: 1,
    });
  };
  openMaterial() {
    window.open(material);
  }
  openChatGPTPDF() {
    window.open(chatGPTPDF);
  }
  openAICreativityPDF() {
    window.open(aiCreativityPDF);
  }
  render() {
    const { showPanel, panel, isStudent } = this.state;
    const user = authService.getCurrentUser();
    let viewMode = true;
    // if (!user) this.props.history.push('/auth')
    if (user) viewMode = false;

    return (
      <React.Fragment>
        <section className="content homepage">
          <div>
            <h1 className="homepage header1 font-main-header text-center">
              Hier lernst du die Grundlagen der KI.
            </h1>
            <div className="homepage header2 font-sub-header text-center">
              Verstehe was KI ist, wo die Wurzeln liegen und wo sie uns im
              Alltag begegnet. Wie funktioniert KI und was sind die Grenzen?
              Lerne in Python zu programmieren und erarbeite dein erstes
              KI-Projekt.
            </div>
          </div>
          <div className="container-fluid home-wrapper">
            <div className="path-tiles">
              {this.state.paths.length > 0 &&
                this.state.paths.map((path, index) => (
                  <Link
                    to={`${path.link}`}
                    key={index}
                    className="homepage-tile layout"
                  >
                    <div className="layout-wrapper">
                      <img
                        className="tile-image"
                        src={`/images/icons/paths/${path.image}`}
                      />
                      <div className="tile-text"> {path.description}</div>
                    </div>
                  </Link>
                ))}
              <div className="homepage-bottom-option">
                <div className="left-option">
                  {!isStudent && (
                    <Link className="verlinkung" to={"/classroom"}>
                      ERSTELLE EINEN VIRTUELLEN KLASSENRAUM
                    </Link>
                  )}
                  {viewMode && (
                    <span className="primary-color-blue-foreground">
                      ERSTELLE EINEN VIRTUELLEN KLASSENRAUM
                    </span>
                  )}
                  <br />

                  {viewMode && (
                    <span className="primary-color-blue-foreground">
                      UNTERRICHTSEINHEITEN ZUM THEMA KI
                    </span>
                  )}

                  {!viewMode && (
                    <span
                      onClick={this.toggleModal}
                      className="primary-color-blue-foreground"
                    >
                      UNTERRICHTSEINHEITEN ZUM THEMA KI
                    </span>
                  )}
                </div>
                <div className="right-option">
                  <span className="primary-color-blue-foreground">
                    Hier geht's zu den Inhalten der
                  </span>
                  <div className="button-container">
                    <Link to={"/code-week"} className="code-week-button">
                      {" "}
                      &rarr; CODE WEEK
                    </Link>
                    {/* <span className="primary-color-blue-foreground">PLACEHOLDER</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showPanel && (
            <div className="overlay-panel-container">
              <div className="gallery">
                <div className="navigator back">
                  {/* {!(panel === 1 ) && <img src="/images/icons/forward_arrow.svg" onClick={()=>{ this.setState({panel:panel-1})}} className="arrow back" />} */}
                </div>
                <div className="overlay-panel-home">
                  <div className="section">
                    {panel === 1 && (
                      <p>
                        <p>
                          In Zusammenarbeit mit{" "}
                          <strong>ZEIT für die Schule</strong> ist ein
                          umfangreiches Unterrichtsmaterial über Künstliche
                          Intelligenz, ChatGPT und Co. entstanden.
                        </p>
                        <p>
                          Durch diese Unterrichtseinheit sollen die
                          Schüler:innen ein faktenbasiertes Verständnis des
                          aktuellen Themas ChatGPT und KI im Allgemeinen
                          erlangen, die Interaktion zwischen Mensch und Maschine
                          näher untersuchen und über die potenziellen
                          Auswirkungen von KI auf die Gesellschaft diskutieren.
                        </p>
                        {!isStudent && (
                          <p>
                            Hier könnt ihr das Unterrichtsmaterial
                            herunterladen:
                            <div className="row">
                              <div className="col-md-12 text-center">
                                <Button
                                  className="btn button-zeit-bold my-2"
                                  label='PDF Unterrichtseinheit "KI, ChatGPT & Co."'
                                  onClick={this.openMaterial}
                                  // href={material} target='_blank' rel='noreferrer noopener'
                                ></Button>
                              </div>
                            </div>
                          </p>
                        )}
                        <br />
                        <p>
                          In diesem Rahmen sind auch zwei lesenswerte Artikel
                          entstanden, die von{" "}
                          <a
                            href="https://www.zeitfuerdieschule.de/materialien/artikel/chatgpt-anfaenge-einer-revolution/"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            ChatGPT
                          </a>{" "}
                          und{" "}
                          <a
                            href="https://www.zeitfuerdieschule.de/materialien/artikel/steckt-die-kreativitaet-der-kuenstlichen-intelligenz-in-der-black-box/"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            Kreativität von KI
                          </a>{" "}
                          handeln. Sie beinhalten spannende Interviews mit
                          Expert:innen und regen zum Diskutieren an.
                        </p>
                        <p>
                          Klickt ihr auf die Links, könnt ihr die Artikel online
                          lesen, oder ladet die PDF-Dateien hier herunter:
                          <div className="row ">
                            <div className="col-md-6 text-center">
                              <Button
                                className="btn button-zeit-bold my-2"
                                label='PDF "ChatGPT"'
                                onClick={this.openChatGPTPDF}
                              ></Button>
                            </div>

                            <div className="col-md-6 text-center">
                              <Button
                                className="btn button-zeit-bold my-2"
                                label='PDF "Kreativität"'
                                onClick={this.openAICreativityPDF}
                              ></Button>
                            </div>
                          </div>
                        </p>
                      </p>
                    )}
                  </div>
                  <div className="close" onClick={this.toggleModal}></div>
                </div>
                <div className="navigator next">
                  {/* {!(panel === 3 ) && <img src="/images/icons/forward_arrow.svg" onClick={()=>{ this.setState({panel:panel+1})}} className="arrow next" />} */}
                </div>
              </div>
            </div>
          )}
        </section>
      </React.Fragment>
    );
  }
}

export default Home;
