import http from "./httpService";

const submission_api_url = process.env.REACT_APP_SUBMISSIONS_API_URL;
const web_api_url = process.env.REACT_APP_WEB_API_URL;

export async function getChallenge(year, paket) {
  return await http.get(`${submission_api_url}/code-week/${year}/${paket}`);
}

export default {
  getChallenge,
};
