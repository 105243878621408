import React from "react";
import Button from "../common/button";
import { withRouter } from "react-router-dom";

function CodeWeekPaket({
  text,
  paketLogo,
  challengeButtonLabel,
  children,
  ...props
}) {
  console.log(props);
  return (
    <>
      <section className="content homepage">
        <div className="container-limit my-limit code-week paket-top-container">
          <img
            className="code-week paket-logo"
            src={paketLogo}
            alt="Logo for Paket 1"
          />
          <p className="font-sub-header">{text}</p>
        </div>
        {children}
        {challengeButtonLabel && (
          <div className="container-fluid home-wrapper text-center">
            <Button
              className="btn button-room-bold my-2"
              label={challengeButtonLabel}
              onClick={() => {
                props.history.push({
                  pathname: props.location.pathname + "/challenge",
                });
                window.scrollTo(0, 0);
              }}
            ></Button>
          </div>
        )}
      </section>
    </>
  );
}

export default withRouter(CodeWeekPaket);
